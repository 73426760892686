<!--start dialog template -->
<template>
  <v-row justify="center">
    <v-dialog :value="dialog" persistent max-width="600px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>
            <span class="headline">{{ title }} Location</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Add Name"
                    v-model="formData.name"
                    required
                    :rules="[isValid.required]"
                    readonly
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="SCHOOL REGISTRATION NUMBER eg. S.340"
                    v-model.trim="formData.reg_no"
                    :rules="[isValid.required, isValid.existRegNoMessage]"
                    required
                    :loading="validateReg"
                    @change.native="validateSchoolNumber($event.target.value)"
                    :messages="existRegNoMessage"
                    v-uppercase
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    label="NECTA REGISTRATION NUMBER eg S.30"
                    v-model.trim="formData.necta_code"
                    :loading="validateNecta"
                    :rules="[isValid.required, isValid.existNectaMessage]"
                    :messages="existNectaNoMessage"
                    required
                    @change.native="validateNectaCode($event.target.value)"
                    v-uppercase
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Description"
                    v-model="formData.description"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" lg="12" md="12" sm="12">
                  <DualSelects
                    :items="levels"
                    :title="'Select School Levels'"
                    :label="'Filter Levels...'"
                    :columnName="'name'"
                    :filter-function="filter"
                    v-model="formData.sectors"
                    :is-open="dialog"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <DialogButtons
            :close="close"
            :save="add"
            :loading="loading"
            :valid="isValidating && valid"
            :title="title"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<!--end dialog-->

<script>
import { get } from "../services";
export default {
  props: [
    "closeDialog",
    "dialog",
    "filterLevels",
    "isEditing",
    "formData",
    "save",
    "loading",
    "levels",
  ],
  name: "LocationDialog",
  data() {
    return {
      valid: false,
      validateNecta: false,
      validateReg: false,
      existRegNoMessage: "",
      existNectaNoMessage: "",

      isValid: {
        required: (value) => !!value || "Required.",

        existRegNoMessage: () => true || this.existRegNoMessage,
        existNectaMessage: () => true || this.existNectaNoMessage,
      },
    };
  },
  methods: {
    filter(data) {
      this.filterLevels(data);
    },
    add() {
      let data = { ...this.formData };
      data.sectors = this.formData.sectors.map((item) => item.id).join(", ");
      data.sectorIds = this.formData.sectors.map((item) => item.id);
      data.necta_code = this.formData.necta_code.toUpperCase();
      data.reg_no = this.formData.reg_no.toUpperCase();

      data.code = data.code
        ? data.code
        : data.name
            .trim()
            .split(" ")
            .join("_")
            .toUpperCase();
      //console.log("data", data);
      this.save(data);
    },

    close() {
      this.closeDialog();
    },

    validateSchoolNumber(data) {
      this.validateReg = true;
      let payload = {
        reg_no: data.toUpperCase(),
      };

      get(payload).then((response) => {
        this.validateReg = false;

        if (response.data[0].data.length > 0) {
          this.isValid.existRegNoMessage = false;
          this.existRegNoMessage = "School  Registration number already taken";
        } else {
          this.isValid.existRegNoMessage = true;
          this.existRegNoMessage = "";
        }
      });

      // console.log("validating now .....");
    },

    validateNectaCode(data) {
      console.log("data", data);
      this.validateNecta = true;
      let payload = {
        necta_code: data.toUpperCase(),
      };

      get(payload).then((response) => {
        this.validateNecta = false;
        if (response.data[0].data.length > 0) {
          this.isValid.existNectaMessage = false;
          this.existNectaNoMessage = "Necta Number already taken";
        } else {
          this.isValid.existNectaMessage = true;
          this.existNectaNoMessage = "";
        }
      });
    },
  },
  computed: {
    title() {
      return this.formData.id ? "Update" : "Create";
    },
    isValidating() {
      return this.validateNecta || this.validateReg ? false : true;
    },
  },
  mounted() {},
};
</script>
